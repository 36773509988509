<template>
  <div class="noClan headerFamily" v-if="!hasClan">
    <div class="flex-item search h-19">
      <el-form>
        <el-form-item class="search-box">
          <el-input class="box w-70" v-model="searchClan" clearable placeholder="搜索家族名称"></el-input>
        </el-form-item>
        <el-form-item class="search-submit">
          <el-button @click="filterFamilyList" type="primary">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="clansBox" v-loading="familyListLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <el-scrollbar style="height: 100%">
        <div class="h-19 clan" v-for="(list, j) in familyListFilter" :key="j">
          <div class="flex">
            <div class="w-11 h-11 ml-5">
              <img class="w-full h-full rounded-full" :src="list.image" />
            </div>
            <div class="ml-3 mr-2 w-52 t-14 clanTitle">
              {{ list.name }}
            </div>
            <el-button class="" type="primary" @click="actionApplyFamily(list)">加入</el-button>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
  <div class="hasClan headerFamily" v-else>
    <div class="clan h-19">
      <div class="flex">
        <div class="ml-5 mr-2 w-52 t-14 clanTitle">家族：再回忆都是空白（23）</div>
      </div>
    </div>
    <div class="clansBox" style="height: 559px">
      <el-scrollbar style="height: 100%">
        <div class="h-19 clan" v-for="(list, j) in clansArr" :key="j">
          <div class="flex">
            <div class="w-11 h-11 ml-5">
              <img class="w-full h-full rounded-full" :src="list.icon" />
            </div>
            <div class="ml-3 mr-2 w-52 t-14 clanTitle">
              {{ list.title }}
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div class="h-19">
      <el-button class="w-88 h-13 mx-4 my-2.5 t-16" type="primary" @click="hasClan = false" style="background-image: linear-gradient(90deg, #00b4ff, #118aff, #1a74ff); border: none">退出家族 </el-button>
    </div>
  </div>
</template>

<script>
import { actionApplyFamily, getFamilyList } from "@/api/family";
import { Message } from "element-ui";
export default {
  name: "headerFamily",
  data() {
    return {
      searchClan: "",
      clansArr: [
        { title: "夜空下最亮的亮点", icon: "http://fakeimg.pl/44x44" },
        { title: "樱花下的浪漫", icon: "http://fakeimg.pl/44x44" },
        { title: "宝石般的笑容", icon: "http://fakeimg.pl/44x44" },
        { title: "夜、美的倾城", icon: "http://fakeimg.pl/44x44" },
        { title: "天气很好，我很好", icon: "http://fakeimg.pl/44x44" },
        { title: "我用蜡笔画你梦中婚礼", icon: "http://fakeimg.pl/44x44" },
        { title: "夜空下最亮的亮点", icon: "http://fakeimg.pl/44x44" },
        { title: "樱花下的浪漫", icon: "http://fakeimg.pl/44x44" },
        { title: "宝石般的笑容", icon: "http://fakeimg.pl/44x44" },
        { title: "夜、美的倾城", icon: "http://fakeimg.pl/44x44" },
        { title: "天气很好，我很好", icon: "http://fakeimg.pl/44x44" },
        { title: "我用蜡笔画你梦中婚礼", icon: "http://fakeimg.pl/44x44" },
        { title: "夜空下最亮的亮点", icon: "http://fakeimg.pl/44x44" },
        { title: "樱花下的浪漫", icon: "http://fakeimg.pl/44x44" },
        { title: "宝石般的笑容", icon: "http://fakeimg.pl/44x44" },
        { title: "夜、美的倾城", icon: "http://fakeimg.pl/44x44" },
        { title: "天气很好，我很好", icon: "http://fakeimg.pl/44x44" },
        { title: "我用蜡笔画你梦中婚礼", icon: "http://fakeimg.pl/44x44" },
      ],
      familyList: [],
      familyListFilter: [],
      familyListLoading: false,
      hasClan: false,
    };
  },
  methods: {
    filterFamilyList() {
      this.familyListFilter = this.familyList.filter((item) => item.name.includes(this.searchClan));
    },
    async init() {
      this.familyListLoading = true;
      const { data } = await getFamilyList({});
      this.familyList = data;
      this.familyListFilter = data;
      this.familyListLoading = false;
      console.log(data);
    },
    async actionApplyFamily(list) {
      // this.hasClan = true
      await actionApplyFamily({ family_id: list.family_id });
      Message.success("申请已提交,请耐心等待");
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>

.headerFamily {
}

</style>
