<template>
  <el-dialog class="popup-dialog" :title="modal.title" :visible.sync="show_" append-to-body :close-on-click-modal="false" lock-scroll :top="modal.top" :class="modal.class" :width="modal.width" @close="doCancel">
    <com v-if="show_ && CommentProps" v-bind="CommentProps" @close="close"></com>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      show_: false,
      CommentProps: null,
      callback_: null,
      modal: {
        top: undefined,
        width: undefined,
        class: undefined,
      },
    };
  },
  provide() {
    return {
      useModal: (config) => {
        this.modal = { ...this.modal, ...config };
      },
      closeModal: (data, done) => {
        this.close(data, done);
      },
    };
  },
  methods: {
    close(data, done) {
      this.show_ = false;
      this.CommentProps = null;

      this.callback_ && this.callback_(data);
      if (typeof done === "function") {
        return done(() => {
          this.show_ = false;
        });
      }
      done && (this.show_ = false);
    },
    doCancel() {
      this.show_ = false;
      this.CommentProps = null;
      this.$nextTick(() => {});
    },
    show(data, callback) {
      this.show_ = false;
      this.CommentProps = data.props || {};
      this.callback_ = callback;
      this.$nextTick(() => {
        this.show_ = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.popup-dialog {
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
}
.headerbtnout-dialog {
  ::v-deep .el-dialog__headerbtn {
    width: 50px;
    height: 50px;
    top: -25px;
    right: -25px;
    background: #fff;
    border-radius: 25px;
    font-size: 17px;

    .el-dialog__close {
      font-size: 28px;
      // color: #fff;
    }
  }
}

</style>
